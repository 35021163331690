<template>
  <div
    v-if="hasSectionWrapper"
    class="flex justify-between items-center p-4 cursor-pointer"
    :data-cy="name"
    :class="{ 'shrink-0': shrinkItems }"
    @click="toggleSection"
  >
    <div
      class="flex space-x-2"
      :class="sectionsContext === 'services' ? 'text-xs' : 'text-xxs'"
    >
      <span
        class="w-5 h-5 flex justify-center items-center bg-gray-200 rounded-full text-headline font-semibold"
      >
        {{ items.length }}
      </span>
      <span
        class="flex items-center text-gray-500"
        :class="
          sectionsContext === 'services'
            ? 'capitalize font-semibold'
            : 'uppercase font-medium'
        "
      >
        {{ name.replaceAll("_", " ") }}
      </span>
    </div>
    <button class="cursor-pointer">
      <icon-base
        :class="{ 'transform rotate-180': isExpanded }"
        height="6"
        width="10"
        icon="dropdown-arrow"
        :icon-color="UTIL_COLORS.LABEL"
      />
    </button>
  </div>
  <div
    v-show="show"
    class="overflow-y-auto space-y-4"
    :data-cy="name + '-items'"
    :class="{ items: hasSectionWrapper && !shrinkItems, 'h-full': shrinkItems }"
  >
    <component
      v-for="item in items"
      :is="componentName"
      :key="item.title"
      :model="item"
      :type="name"
    />
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { UTIL_COLORS } from "@/helpers/constants";
import { useEmitter } from "@/hooks/common";
import { CLOSE_SIDE_PANEL_SECTIONS } from "@/helpers/constants/events";

const props = defineProps({
  name: {
    type: String,
    default: ""
  },
  componentName: {
    type: String,
    default: ""
  },
  isInitiallyExpanded: {
    type: Boolean,
    default: false
  },
  items: {
    type: Array,
    default: () => []
  },
  shrinkItems: {
    type: Boolean,
    default: false
  },
  sectionsContext: {
    type: String,
    required: true
  }
});

const emitter = useEmitter();
const isExpanded = ref(props.isInitiallyExpanded);
const hasSectionWrapper = computed(() => props.name !== "null");
const show = computed(() => isExpanded.value || !hasSectionWrapper.value);

emitter.on(CLOSE_SIDE_PANEL_SECTIONS, (panelName) => {
  isExpanded.value = !isExpanded.value && panelName === props.name;
});

const toggleSection = () => {
  emitter.emit(CLOSE_SIDE_PANEL_SECTIONS, props.name);
};
</script>
<style scoped>
.items {
  max-height: 38% !important;
}
</style>
